import React, { useContext, useState } from "react"
import { Link as GatsbyLink } from "gatsby"
import { Heading, Text, Link, Grid, Box } from "theme-ui"

import { InterfaceContext } from "../../contexts/InterfaceContext"
import { StoreContext } from "../../contexts/StoreContext"
import BlockText from "../../utils/block-text"
import Button from "../Button"

import ProductImageViewer from "./ProductImageViewer"
import ProductOptions from "./ProductOptions"

const ProductDetails = (props) => {
  const {
    heading,
    maxVariantPrice,
    colour,
    _rawDetails,
    variants,
    images,
    sourceData: { productType },
    collection: { intro },
    collections: [collection],
  } = props

  const {
    title: episodeName,
    slug: { current: episodeSlug },
  } = collection

  const price = new Intl.NumberFormat("en-AU", {
    style: "currency",
    currency: "AUD",
  }).format(maxVariantPrice)

  const { addVariantToCart } = useContext(StoreContext)
  const { toggleCart } = useContext(InterfaceContext)
  const hasVariants = variants.length > 1
  const isVariantsOutOfStock =
    hasVariants &&
    variants.every((x) => x.sourceData.availableForSale === false)
  const isOutOfStock =
    (!hasVariants && !variants[0].sourceData.availableForSale) ||
    isVariantsOutOfStock
  const [variantId, setVariantId] = useState(
    hasVariants ? "" : variants[0].shopifyVariantID
  )

  const handleVariantChange = (event) => {
    event.preventDefault()
    setVariantId(event.target.value)
  }

  const handleSubmit = (event) => {
    event.preventDefault()

    if (variantId) {
      addVariantToCart(variantId, 1)
      toggleCart()
    } else {
      // eslint-disable-next-line no-alert
      alert("Please select a size")
    }
  }

  return (
    <Box bg="background" px={[24, "10%"]} py={[0, 5]}>
      <Grid gap={["20px", "10%"]} columns={[1, 2, "3fr 370px"]}>
        <Box mx={[-24, 0]}>
          <ProductImageViewer images={images} />
        </Box>
        <Box as="form" onSubmit={handleSubmit} noValidate>
          <Grid gap={3}>
            <Box>
              <Link
                as={GatsbyLink}
                fontSize={2}
                variant="episode"
                to={`/${episodeSlug}`}
              >
                {episodeName}
              </Link>
            </Box>
            <Heading>{heading}</Heading>
            <Text variant="body">{price}</Text>
            <Text as="p" variant="small">
              {intro}
            </Text>
            <ProductOptions
              type={productType}
              colour={colour}
              variants={variants}
              onChange={handleVariantChange}
              isSoldOut={isOutOfStock}
            />
            <AddToCart isSoldOut={isOutOfStock} />
            <Box sx={{ fontSize: 0 }}>
              <BlockText blocks={_rawDetails} />
            </Box>
          </Grid>
        </Box>
      </Grid>
    </Box>
  )
}

const AddToCart = ({ isSoldOut, isDisabled }) => {
  return (
    <Button type="submit" disabled={isSoldOut || isDisabled} variant="standard">
      {isSoldOut ? "Sold out" : "Add to Cart"}
    </Button>
  )
}

export default ProductDetails
