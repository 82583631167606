import React from "react"
import { graphql } from "gatsby"

import Layout from "../components/Layout"
import Panels from "../components/panels"
import ProductDetails from "../components/product-details"
import Synopsis from "../components/Synopsis"
import DesignerNotes from "../components/DesignerNotes"

export const query = graphql`
  query($id: String!) {
    sanityShopifyProduct(id: { eq: $id }) {
      heading
      colour
      maxVariantPrice
      images {
        asset {
          fluid {
            ...GatsbySanityImageFluid_noBase64
          }
        }
      }
      colour
      sourceData {
        productType
      }
      _rawDetails
      variants {
        shopifyVariantID
        title
        sourceData {
          availableForSale
        }
      }
      collection {
        intro
        _rawDesignerNotes
      }
      collections {
        title
        number
        _rawSynopsis
        slug {
          current
        }
      }
      slug {
        current
      }
      panels {
        ... on SanityGraphic {
          _key
          _type
          image {
            asset {
              fluid {
                src
              }
            }
          }
          mobile {
            asset {
              fluid {
                src
              }
            }
          }
          backgroundColour {
            hex
          }
        }
        ... on SanityOneUp {
          _key
          _type
          image {
            asset {
              fluid {
                ...GatsbySanityImageFluid_noBase64
              }
            }
          }
          mobile {
            asset {
              fluid {
                ...GatsbySanityImageFluid_noBase64
              }
            }
          }
        }
        ... on SanityOneUpOffset {
          _key
          _type
          image {
            asset {
              fluid {
                ...GatsbySanityImageFluid_noBase64
              }
            }
          }
          mobile {
            asset {
              fluid {
                ...GatsbySanityImageFluid_noBase64
              }
            }
          }
        }
        ... on SanityTwoUp {
          _key
          _type
          imageOne {
            asset {
              fluid {
                ...GatsbySanityImageFluid_noBase64
              }
            }
          }
          imageTwo {
            asset {
              fluid {
                ...GatsbySanityImageFluid_noBase64
              }
            }
          }
        }
      }
    }
  }
`

const ProductTemplate = ({ data }) => {
  const productData = data.sanityShopifyProduct

  return (
    <Layout>
      <ProductDetails {...productData} />
      <Synopsis {...productData} />
      <Panels {...productData} />
      <DesignerNotes {...productData} />
    </Layout>
  )
}

export default ProductTemplate
