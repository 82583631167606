import React from "react"
import { useStaticQuery, graphql } from "gatsby"
import Img from "gatsby-image"
import { Box } from "theme-ui"

const ProductSizeGuide = ({ type }) => {
  const data = useStaticQuery(graphql`
    query {
      longsleeve: file(relativePath: { eq: "ep1-ls-size-guide.jpg" }) {
        childImageSharp {
          fluid {
            ...GatsbyImageSharpFluid_withWebp_noBase64
          }
        }
      }
      tee: file(relativePath: { eq: "ep1-tee-size-guide.jpg" }) {
        childImageSharp {
          fluid {
            ...GatsbyImageSharpFluid_withWebp_noBase64
          }
        }
      }
      sweatshirt: file(relativePath: { eq: "ep2-sweatshirt-size-guide.jpg" }) {
        childImageSharp {
          fluid {
            ...GatsbyImageSharpFluid_withWebp_noBase64
          }
        }
      }
    }
  `)

  return (
    <Box sx={{ maxWidth: 728, mx: "auto" }}>
      {type === "T-Shirt" && <Img fluid={data.tee.childImageSharp.fluid} />}
      {type === "Sweatshirt" && (
        <Img fluid={data.sweatshirt.childImageSharp.fluid} />
      )}
      {type === "Longsleeve" && (
        <Img fluid={data.longsleeve.childImageSharp.fluid} />
      )}
    </Box>
  )
}

export default ProductSizeGuide
