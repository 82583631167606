/** @jsx jsx */
import { jsx, Flex, Box } from "theme-ui"
import Image from "gatsby-image"

const OneUpOffset = ({ desktopImage, mobileImage }) => {
  if (!mobileImage || !desktopImage) {
    // eslint-disable-next-line no-console
    console.error("OneUpOffset panel missing images")
    return null
  }

  const sources = [
    mobileImage.asset.fluid,
    {
      ...desktopImage.asset.fluid,
      media: `(min-width: 64em)`,
    },
  ]

  return (
    <Flex bg="muted" sx={{ justifyContent: "left" }}>
      <Box p={[0, 0, "10% 0 10% 10%"]} sx={{ width: ["100%", "100%", "56%"] }}>
        <Image fluid={sources} />
      </Box>
    </Flex>
  )
}

export default OneUpOffset
