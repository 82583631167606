import React from "react"
import Graphic from "./Graphic"
import OneUp from "./OneUp"
import OneUpOffset from "./OneUpOffset"
import TwoUp from "./TwoUp"

const Panels = (props) => {
  const { panels } = props

  return (
    <>
      {panels.map((panel) => {
        switch (panel._type) {
          case "graphic":
            return (
              <Graphic
                key={panel._key}
                desktopSrc={panel.image}
                mobileSrc={panel.mobile}
                bg={panel.backgroundColour.hex}
              />
            )

          case "oneUp":
            return (
              <OneUp
                key={panel._key}
                desktopImage={panel.image}
                mobileImage={panel.mobile}
              />
            )

          case "oneUpOffset":
            return (
              <OneUpOffset
                key={panel._key}
                desktopImage={panel.image}
                mobileImage={panel.mobile}
              />
            )

          case "twoUp":
            return (
              <TwoUp
                key={panel._key}
                imageOne={panel.imageOne}
                imageTwo={panel.imageTwo}
              />
            )

          default:
            return null
        }
      })}
    </>
  )
}

export default Panels
