import React, { useContext, useState } from "react"
import { Box, Text, Grid, Close, Button } from "theme-ui"
import ReactModal from "react-modal"

import { InterfaceContext } from "../../contexts/InterfaceContext"
import ProductSizeGuide from "./ProductSizeGuide"

const mobileModalStyles = {
  overlay: {
    backgroundColor: "rgba(0, 0, 0, 0.5)",
  },
  content: {
    position: "absolute",
    top: 0,
    bottom: 0,
    right: 0,
    left: 0,
    border: "none",
    overflow: "auto",
  },
}

const desktopModalStyles = {
  overlay: {
    backgroundColor: "rgba(0, 0, 0, 0.5)",
  },
  content: {
    position: "absolute",
    top: "40px",
    bottom: "40px",
    left: "50%",
    maxWidth: "720px",
    border: "none",
    transform: "translateX(-50%)",
    overflow: "auto",
  },
}

const ProductOptions = ({ colour, variants, type, isSoldOut, onChange }) => {
  const [variantId] = useState("")
  const { isDesktopViewport } = useContext(InterfaceContext)
  const [isModalOpen, setModalOpen] = useState(false)
  const hasVariants = variants.length > 1

  const handleModalOpen = (event) => {
    event.preventDefault()
    setModalOpen(true)
  }

  const handleModalClose = (event) => {
    event.preventDefault()
    setModalOpen(false)
  }

  const outlineStyles = {
    borderWidth: "1px",
    borderStyle: "solid",
    borderColor: "muted",
    borderRadius: "50px",
    lineHeight: "20px",
    display: "block",
    padding: "12px 0 10px",
  }

  const textStyles = {
    fontSize: 16,
    fontWeight: 500,
    fontFamily: "body",
  }

  return (
    <Box>
      <Grid gap={2}>
        <Grid gap={3} columns={2} px={1}>
          <Text as="p" variant="small" sx={{ lineHeight: 1, py: 2 }}>
            {type}
          </Text>
          {(type === "T-Shirt" ||
            type === "Longsleeve" ||
            type === "Sweatshirt") && (
            <Box sx={{ textAlign: "right" }}>
              <Button variant="text" onClick={handleModalOpen}>
                Size guide
              </Button>
            </Box>
          )}
        </Grid>
        <Grid gap={3} columns={2}>
          <Box sx={outlineStyles}>
            <Box sx={{ ...textStyles, transform: "scale(0.75)" }}>{colour}</Box>
          </Box>
          {hasVariants && (
            <Box sx={outlineStyles}>
              <Box
                as="select"
                defaultValue={variantId}
                onChange={onChange}
                disabled={isSoldOut}
                sx={{
                  ...textStyles,
                  border: 0,
                  lineHeight: "20px",
                  padding: 0,
                  display: "block",
                  width: "100%",
                  appearance: "none",
                  backgroundImage:
                    "url(data:image/svg+xml;base64,PD94bWwgdmVyc2lvbj0iMS4wIiA/PjxzdmcgZGF0YS1uYW1lPSJMYXllciAxIiBpZD0iTGF5ZXJfMSIgdmlld0JveD0iMCAwIDY0IDY0IiB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciPjx0aXRsZS8+PHBhdGggZD0iTTMyLDUxLjUsMCwxOC44Myw2LjYyLDEyLjUsMzIsMzguNzYsNTcuMzgsMTIuNSw2NCwxOC44M1oiIGRhdGEtbmFtZT0iJmx0O0NvbXBvdW5kIFBhdGgmZ3Q7IiBpZD0iX0NvbXBvdW5kX1BhdGhfIi8+PC9zdmc+)",
                  backgroundPosition: "right center",
                  backgroundRepeat: "no-repeat",
                  backgroundSize: "8px 10px",
                  transform: "scale(0.75)",
                }}
              >
                <option value="">Size</option>
                {variants.map(
                  ({
                    shopifyVariantID,
                    title,
                    sourceData: { availableForSale },
                  }) => (
                    <option
                      value={shopifyVariantID}
                      key={shopifyVariantID}
                      disabled={!availableForSale}
                    >
                      {title}
                    </option>
                  )
                )}
              </Box>
            </Box>
          )}
        </Grid>
      </Grid>

      <ReactModal
        isOpen={isModalOpen}
        onRequestClose={handleModalClose}
        contentLabel="Sizing Modal"
        style={isDesktopViewport ? desktopModalStyles : mobileModalStyles}
        ariaHideApp={false}
      >
        <ProductSizeGuide type={type} />
        <Close
          onClick={handleModalClose}
          sx={{
            position: "absolute",
            top: 15,
            right: 15,
            cursor: "pointer",
            transition: "color 0.3s ease",
            "&:hover": {
              color: "highlight",
            },
          }}
        />
      </ReactModal>
    </Box>
  )
}

export default ProductOptions
