import React from "react"
import Image from "gatsby-image"
import { Grid, AspectRatio } from "theme-ui"
import SwipeableViews from "react-swipeable-views"

const ProductImageViewer = ({ images }) => {
  return (
    <>
      <Grid columns={2} gap="2px" sx={{ display: ["none", "grid"] }}>
        {images.map((image, idx) => {
          return (
            // eslint-disable-next-line react/no-array-index-key
            <AspectRatio key={idx} ratio={0.87}>
              <Image fluid={image.asset.fluid} />
            </AspectRatio>
          )
        })}
      </Grid>
      <SwipeableViews disableLazyLoading>
        {images.map((image, idx) => (
          // eslint-disable-next-line react/no-array-index-key
          <Image key={idx} fluid={image.asset.fluid} />
        ))}
      </SwipeableViews>
    </>
  )
}

export default ProductImageViewer
