/** @jsx jsx */
import { jsx, Box, AspectImage } from "theme-ui"

const Graphic = ({ bg, desktopSrc, mobileSrc }) => {
  return (
    <Box bg={bg}>
      {desktopSrc && (
        <Box
          sx={{
            display: ["none", "none", "block"],
          }}
        >
          <AspectImage
            src={desktopSrc.asset.fluid.src}
            width="1440"
            height="695"
            ratio={1440 / 695}
            loading="lazy"
            alt=""
            sx={{
              maxWidth: "none",
              width: "100%",
            }}
          />
        </Box>
      )}
      {mobileSrc && (
        <Box
          sx={{
            display: ["block", "block", "none"],
          }}
        >
          <AspectImage
            src={mobileSrc.asset.fluid.src}
            width="1500"
            height="1369"
            ratio={1500 / 1360}
            loading="lazy"
            alt=""
            sx={{
              display: ["block", "block", "none"],
            }}
          />
        </Box>
      )}
    </Box>
  )
}

export default Graphic
