import React from "react"
import { Heading, Text, Box } from "theme-ui"

import BlockText from "../utils/block-text"

const DesignerNotes = ({ collection: { _rawDesignerNotes } }) => {
  return (
    <Box bg="background" px={[24, "10%"]} py={[4, 5]}>
      <Box sx={{ fontSize: [6, 40, 60], mb: [2, 4], lineHeight: 1 }}>
        <Text
          as="span"
          sx={{
            fontFamily: "fancyHeading",
            color: "highlight",
            fontSize: "0.2em",
            textTransform: "uppercase",
          }}
        >
          A closer look
        </Text>
        <Heading as="h3" sx={{ fontSize: "inherit", fontWeight: "bold" }}>
          Designer notes
        </Heading>
      </Box>

      <Box
        sx={{
          px: [0, "5%", "10%"],
          columnCount: [1, 2],
          columnGap: [4, 5],
          fontSize: [0, 1, 2],
        }}
      >
        <BlockText blocks={_rawDesignerNotes} />
      </Box>
    </Box>
  )
}

export default DesignerNotes
