/** @jsx jsx */
import { jsx } from "theme-ui"
import Image from "gatsby-image"

const OneUp = ({ desktopImage, mobileImage }) => {
  if (!mobileImage || !desktopImage) {
    // eslint-disable-next-line no-console
    console.error("OneUp panel missing images")
    return null
  }

  const sources = [
    mobileImage.asset.fluid,
    {
      ...desktopImage.asset.fluid,
      media: `(min-width: 64em)`,
    },
  ]

  return <Image fluid={sources} />
}

export default OneUp
