/** @jsx jsx */
import { jsx, Grid, Box } from "theme-ui"
import Image from "gatsby-image"

const TwoUp = ({ imageOne, imageTwo }) => {
  return (
    <Box bg="background" px={[24, 24, "10%"]} py={[24, 24, "10%"]}>
      <Grid gap={[24, 24, 34]} columns={[1, 1, 2]}>
        {imageOne && <Image fluid={imageOne.asset.fluid} />}
        {imageTwo && <Image fluid={imageTwo.asset.fluid} />}
      </Grid>
    </Box>
  )
}

export default TwoUp
